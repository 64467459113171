<template>
  <div class="ak-text container-layout-custom" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row">
        <div class="text-h4 col-md-9">Browse Modules</div>
      </div>
      <div class="row q-mt-lg">
        <q-breadcrumbs v-if="breadcrumbs.length > 1">
          <q-breadcrumbs-el v-for="(category, i) in breadcrumbs" :key="i" :label="category.name" :to="{ name: 'browse', params: { categoryUid: category.uid } }"/>
        </q-breadcrumbs>
      </div>
        <!-- CATEGORIES -->
        <div v-if="displayCategories.length > 0" class="row q-mt-xl">
          <div id="categories" class="full-width">
            <div class="row">
              <div class="text-h5 q-mb-lg" id="categoriesTitle">{{ selectedCategory ? selectedCategory.name : 'Main' }} Categories</div>
            </div>
            <div class="row q-gutter-lg">
              <div v-for="category in displayCategories" :key="category.uid">
                <q-card v-if="showCategory(category)" class="cursor-pointer moduleCard" style="min-width: 250px">
                  <q-img :src="category.thumbnail_path"  @click="$router.push({ name: 'browse', params: { categoryUid: category.uid } }).catch(() => {})">
                    <div v-if="category.settings.overlayTitle" class="absolute-full category-skim text-center text-weight-medium flex flex-center">
                      <div class="font-oswald" :style="`font-size: ${getFontSize(category.name)}`">{{ category.name }}</div>
                    </div>
                  </q-img>
                </q-card>
              </div>
            </div>
          </div>

          <!-- Newest Modules. Should only appear on Browse main page -->
          <div class="full-width q-mt-xl" v-if="!selectedCategory && newModules.length > 0">
            <module-slider :selectedCategory="selectedCategory" :modules="newModules"></module-slider>
          </div>
        </div>

        <!-- Modules for a specific category -->
        <div class="full-width q-mt-md" v-else>
          <div class="full-width" v-if="selectedCategory">
            <div class="row full-width justify-end">
              <div class="text-caption text-weight-light q-mr-sm">Data View</div>
            </div>
            <div class="row full-width justify-between">
              <div class="text-h5 q-mb-lg" id="categoriesTitle">{{ selectedCategory ? selectedCategory.name : 'Main' }} Modules</div>
              <data-view-switcher />
            </div>
            <modules-view :modules="modules" :page="page" :pageMax.sync="pageMax" :resultsPerPage.sync="resultsPerPage" :resultsPerPageOptions.sync="resultsPerPageOptions"  :sortBy.sync="sortBy"></modules-view>
            <div v-if="pageMax > 1" class="row justify-center full-width q-mt-xl">
              <q-pagination v-model="page" :max="pageMax" :max-pages="5" :ellipses="false" :boundary-numbers="false" :direction-links="true">
              </q-pagination>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ModuleSlider from 'components/browseCourses/ModuleSlider'
import ModulesView from 'components/ModulesView'
import DataViewSwitcher from 'components/DataViewSwitcher'

export default {
  name: 'BrowsePage',
  components: { ModuleSlider, ModulesView, DataViewSwitcher },
  data () {
    return {
      breadcrumbs: [],
      modules: [],
      categories: [],
      newModules: [],
      selectedCategory: null,
      currentLevelCategories: [],
      page: 0,
      pageMax: 0,
      resultsPerPage: 25,
      resultsPerPageOptions: [25, 50, 100, 'View All'],
      sortBy: { label: 'Newest to Oldest', value: '_created_desc' }
    }
  },
  computed: {
    displayCategories () {
      return this.currentLevelCategories.filter(category => this.showCategory(category))
    },
    displayModules () {
      let startIndex = (this.page - 1) * this.resultsPerPage
      return this.modules.slice(startIndex, startIndex + this.resultsPerPage)
    }
  },
  methods: {
    showCategory (category) {
      if (category.hasModules) return true

      if (category.children) {
        return this.childHasChildrenOrModules(category)
      }
    },
    childHasChildrenOrModules (category) {
      // we want to check all children and see if they have children or modules - we only need one to pass
      return category.children.some(child => {
        if (child.hasModules) return true

        if (child.children) {
          return this.childHasChildrenOrModules(child)
        }
      })
    },
    canViewIfTutorial (categoryUid) {
      if (categoryUid === 'site-admin-tutorials' && !this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
        this.$router.push({ name: 'browse' }).catch(err => err)
        this.$failureMsg(`You do not have permission to view those tutorials`)
      }

      if (categoryUid === 'location-admin-tutorials' && !this.$isAllowed(['||', 'master-admin', 'location-admin'], true)) {
        this.$router.push({ name: 'browse' }).catch(err => err)
        this.$failureMsg(`You do not have permission to view those tutorials`)
      }
    },
    selectCategory (categoryUid) {
      // checking if they are allowed to see tutorial category
      this.canViewIfTutorial(categoryUid)

      if (categoryUid !== undefined) {
        this.selectedCategory = this.$store.getters['categories/getByUid'](this.categories, categoryUid)

        if (this.selectedCategory.hasModules) {
          this.getModules()
        } else {
          // need to reset the pagination to 0 or else the route query might not update on next category visit
          this.page = 0
        }

        if (this.selectedCategory.uid === 'lms-training') {
          // we only want to display relevant training based on user's role
          if (this.$isAllowed(['||', 'master-admin', 'site-admin'], true)) {
            this.currentLevelCategories = this.selectedCategory.children.filter(cat => cat.uid === 'site-admin-tutorials' || cat.uid === 'user-tutorials')
          } else if (this.$isAllowed(['||', 'location-admin'], true)) {
            this.currentLevelCategories = this.selectedCategory.children.filter(cat => cat.uid === 'location-admin-tutorials' || cat.uid === 'user-tutorials')
          } else {
            this.currentLevelCategories = this.selectedCategory.children.filter(cat => cat.uid === 'user-tutorials')
          }
        } else if (this.selectedCategory.children !== undefined) {
          this.currentLevelCategories = this.selectedCategory.children
        } else {
          this.currentLevelCategories = []
        }
      } else {
        this.selectedCategory = null
        this.currentLevelCategories = this.categories
      }
      // reset breadcrumbs because we are about to rebuild it
      this.breadcrumbs = []
      this.buildBreadcrumb()
    },
    buildBreadcrumb (category = this.selectedCategory) {
      if (!category) return // there's no category selected(main page) then just return
      // determine if it has a parent
      if (category.parent_uid) {
        this.buildBreadcrumb(this.$store.getters['categories/getByUid'](this.categories, category.parent_uid))
      } else {
        // no parent so add the main category breadcrumb
        this.breadcrumbs.push({ name: 'Main', value: 'browse' })
      }
      // we can now finally add our original category at the end of the recursion
      this.breadcrumbs.push(category)
    },
    getModules (categoryUid) {
      this.$moduleSearch.clearCache()
      this.$moduleSearch.search([
        {
          indexName: this.$consts.MODULES_INDEX + this.sortBy.value,
          query: '',
          params: {
            hitsPerPage: 100000, // max for algolia
            facetFilters: [
              `categories:${this.selectedCategory.uid}`
            ]
          }
        }
      ], (err, content) => {
        if (err) throw err
        this.modules = content.results['0'].hits

        if (this.$route.query && this.$route.query.p && this.modules.length > this.resultsPerPageOptions[0]) {
          this.page = parseInt(this.$route.query.p)
        } else {
          this.page = 1
        }
      })
    },
    getFontSize (name) {
      if (name.length <= 6) {
        return '56px'
      } else if (name.length <= 10) {
        return '48px'
      } else if (name.length <= 14) {
        return '40px'
      } else if (name.length <= 18) {
        return '32px'
      } else if (name.length <= 22) {
        return '28px'
      } else if (name.length <= 26) {
        return '24px'
      } else if (name.length <= 30) {
        return '20px'
      } else {
        return '16px'
      }
    }
  },
  watch: {
    categories: function (newCategories) {
      this.currentLevelCategories = newCategories
      this.selectCategory(this.$route.params.categoryUid)
    },
    '$route' (to, from) {
      this.selectCategory(to.params.categoryUid)
    },
    page: function (newVal, oldVal) {
      this.$scrollToElement('#q-app')
      if (newVal === 0) return

      let correctRoute = { name: 'browse', params: { categoryUid: this.selectedCategory.uid }, query: { p: this.page } }
      if (oldVal === 0) {
        this.$router.replace(correctRoute).catch(err => err)
      } else {
        this.$router.push(correctRoute).catch(err => err)
      }
    },
    resultsPerPage: function () {
      this.page = 1
    },
    sortBy: function () {
      this.getModules()
    }
  },
  created () {
    const d = new Date()
    this.$moduleSearch.clearCache()
    this.$moduleSearch.search([
      {
        indexName: this.$consts.MODULES_INDEX,
        query: '',
        params: {
          hitsPerPage: 60,
          filters: 'new_until_timestamp>' + Math.floor(d.setDate(d.getDate() - 7) / 1000),
          facetFilters: [
            'customer_uid:' + this.$store.getters['auth/company'].uid
          ]
        }
      }
    ], (err, content) => {
      if (err) throw err
      this.newModules = content.results['0'].hits
    })

    this.$q.loading.show({ message: 'Loading...' })
    this.$store.dispatch('categories/fetch', 'view').then((resp) => {
      this.categories = resp
      this.$q.loading.hide()
    })

    this.moduleInfo = this.newModules[0]
  }
}
</script>

<style lang="stylus">
.font-oswald
  font-family 'Oswald', sans-serif
.category-skim
  background rgba(0,0,0,0.57) !important
.moduleCard
  position relative
  transition .3s
  &:hover
    box-shadow 0 7px 8px -2px rgba(0,0,0,0.2), 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12) !important
    border-color transparent
    transform translateY(-5px)
</style>
