<template>
  <div class="row">
    <div class="full-width" v-if="modules.length > 0">
      <div class="row justify-between">
        <div class="col-xs-12 col-md-6 q-pa-sm">
          <q-input v-model="filter" debounce="500" label="Filter Selection">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <div class="col-xs-12 col-md-3 q-pa-sm">
          <q-select v-model="sortByDisplay" :options="sortByOptions" @input="syncToParent('sortBy', $event)" label="Sort By" popup-content-class="bg-lmsBackground primaryText-text" />
        </div>
      </div>
      <div v-if="currentPageModules.length > 0" class="row full-width justify-end">
        <q-select :value="resultsPerPage" @input="syncToParent('resultsPerPage', $event)" :options="resultsPerPageOptions" label="Results Per Page" style="width: 170px" popup-content-class="bg-lmsBackground primaryText-text" />
      </div>
      <div class="row full-width q-mt-xl q-gutter-lg" v-if="this.$store.getters['auth/dataView'] === 'grid'">
        <module-grid-item v-for="module in currentPageModules" :module="module" :key="module.uid" />
      </div>
      <div class="row full-width q-mt-xl" v-if="this.$store.getters['auth/dataView'] === 'list'">
        <q-list separator class="full-width">
          <module-list-item v-for="module in currentPageModules" :module="module" :key="module.uid" />
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleListItem from 'components/ModuleListItem'
import ModuleGridItem from 'components/ModuleGridItem'

export default {
  name: 'ModulesView',
  components: { ModuleListItem, ModuleGridItem },
  props: {
    modules: {},
    bookmarkable: {
      default: true
    },
    emptyMessage: {
      default: 'No Modules'
    },
    resultsPerPage: {
      default: 25
    },
    resultsPerPageOptions: {
      default: [25, 50, 100, 'View All']
    },
    page: {
      default: 1
    },
    pageMax: {
      default: 1
    },
    sortBy: {
      default: { label: 'Newest to Oldest', value: '_created_desc' }
    }
  },
  data () {
    return {
      filter: '',
      sortByDisplay: { label: 'Newest to Oldest', value: '_created_desc' },
      sortByOptions: [{ label: 'Oldest to Newest', value: '_created_asc' }, { label: 'Newest to Oldest', value: '_created_desc' }, { label: 'A - Z', value: '_a-z' }, { label: 'Z - A', value: '_z-a' }]
    }
  },
  computed: {
    allowedModules () {
      return this.modules.filter(module => this.isAllowedToView(module))
    },
    filteredModules () {
      return this.allowedModules.filter(module => module.title.toLowerCase().includes(this.filter.toLowerCase()))
    },
    currentPageModules () {
      let startIndex = (this.page - 1) * this.resultsPerPage
      return this.filteredModules.slice(startIndex, startIndex + this.resultsPerPage)
    },
    viewPageMax () {
      return Math.ceil(this.filteredModules.length / this.resultsPerPage)
    }
  },
  watch: {
    viewPageMax () {
      this.syncToParent('pageMax', this.viewPageMax)
    }
  },
  methods: {
    isAllowedToView (module) {
      if (module.browsable === 'true') {
        return true
      } else if (module.browsable === 'false') {
        return false
      } else if (module.browsable === 'restricted') {
        return (this.$store.getters['auth/userDepartmentUids'].some(departmentUid => module.restrictions.departments.includes(departmentUid)) ||
            this.$store.getters['auth/user'].roles.map(role => role.uid).some(roleUid => module.restrictions.roles.includes(roleUid)) ||
            module.restrictions.locations.includes(this.$store.getters['auth/customer']))
      }
    },
    syncToParent (prop, value) {
      this.$emit(`update:${prop}`, value)
    }
  }
}
</script>
