<template>
  <div class="q-gutter-sm">
    <q-btn flat dense :color="(this.$store.getters['auth/dataView'] == 'grid') ? 'primary' : ''" icon="view_comfy" @click="$store.dispatch('auth/setDataView', 'grid')"><q-tooltip>Show modules in grid layout</q-tooltip></q-btn>
    <q-btn flat dense :color="(this.$store.getters['auth/dataView'] == 'list') ? 'primary' : ''" icon="view_list" @click="$store.dispatch('auth/setDataView', 'list')"><q-tooltip>Show modules in list layout</q-tooltip></q-btn>
  </div>
</template>

<script>
export default {
  name: 'DataViewSwitcher'
}
</script>
