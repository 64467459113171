<template>
  <div v-if="allowedModules.length > 0">
    <div class="row">
      <div class="text-h5 q-mb-lg">{{ selectedCategory ? selectedCategory.name : 'Newest' }} Modules</div>
    </div>

    <div class="row">
      <!-- IE Carousel -->
      <q-carousel v-if="isIE" v-model="moduleSlide" height="300px" ref="moduleSlide" animated infinite transition-prev="slide-right" transition-next="slide-left" class="full-width bg-lmsBackground">
        <q-carousel-slide v-for="(chunk, index) in chunkedModules" :name="index" :key="index">
          <div class="row q-gutter-sm justify-center" style="align-items: center;">
            <q-card flat bordered v-for="module in chunk" :name="module.uid" :key="module.uid" class="cursor-pointer moduleCard bg-lmsBackground" style="min-width: 250px;"  @click="$router.push({ name: 'module', params: { moduleId: module.uid } }).catch(() => {})">
              <q-img :src="module.thumbnail_path">
                <q-btn dense round flat color="primary" class="float-right" :icon="$store.getters['bookmarks/isByUid'](module.uid) ? 'bookmark' : 'bookmark_border'" @click.stop="toggleBookmark(module)">
                  <q-tooltip>Toggle Bookmark</q-tooltip>
                </q-btn>
              </q-img>
              <div class="modulePlay row justify-center">
                <q-icon name="play_circle_outline" size="48px"/>
              </div>
              <q-card-section class="row" style="justify-content: space-between; align-items: center;">
                <div>
                  <span class="text-subtitle2">{{ module.title | truncate(25) }}</span>
                  <br />
                  <span class="text-caption">{{ module.description | truncate(30) }}</span>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </q-carousel-slide>

        <template v-slot:control>
          <q-carousel-control position="left" :offset="[18, 18]" class="q-gutter-xs" style="display: flex; align-items: center; z-index: 2;">
            <q-btn flat round dense text-color="primaryText" class="primaryText-text" icon="chevron_left" @click="$refs.moduleSlide.previous();" />
          </q-carousel-control>
          <q-carousel-control position="right" :offset="[18, 18]" class="q-gutter-xs" style="display: flex; align-items: center; z-index: 2;">
            <q-btn flat round dense text-color="primaryText" class="primaryText-text" icon="chevron_right" @click="$refs.moduleSlide.next();" />
          </q-carousel-control>
        </template>
      </q-carousel>

      <!-- All other browser's carousel -->
      <q-carousel v-else v-model="moduleSlide" ref="moduleSlide" animated infinite transition-prev="slide-right" transition-next="slide-left" class="full-width bg-lmsBackground">
        <q-carousel-slide v-for="(chunk, index) in chunkedModules" :name="index" :key="index">
          <div class="row q-gutter-sm justify-center full-height" style="align-items: center;">
            <q-card square v-for="(module, index) in chunk" :name="index" :key="index" class="moduleSliderCard cursor-pointer" @mouseenter="hoveredModule = module; handleHover(index, module)" @mouseleave="hoveredModule = {}; removeHoverEffect()">
              <q-img :src="module.thumbnail_path" @click="handleModuleClick('route', module)" style="width: 250px" />
              <div class="modulePlay row justify-center" @click="handleModuleClick('route', module)">
                <q-icon name="play_circle_outline" size="32px"/>
              </div>
              <div class="expandArrow row justify-center">
                <q-icon class="full-width" name="expand_more" @click="handleModuleClick('expand', module, index)" size="32px"/>
              </div>
            </q-card>
          </div>
        </q-carousel-slide>

        <template v-slot:control>
          <q-carousel-control position="left" :offset="[18, 18]" class="q-gutter-xs" style="display: flex; align-items: center; z-index: 2;">
            <q-btn flat round dense text-color="primaryText" class="primaryText-text" icon="chevron_left" @click="closeModulePreview(); $refs.moduleSlide.previous();" />
          </q-carousel-control>
          <q-carousel-control position="right" :offset="[18, 18]" class="q-gutter-xs" style="display: flex; align-items: center; z-index: 2;">
            <q-btn flat round dense text-color="primaryText" class="primaryText-text" icon="chevron_right" @click="closeModulePreview(); $refs.moduleSlide.next();" />
          </q-carousel-control>
        </template>
      </q-carousel>
      <div id="expandedModulePreview" class="expandedModuleWrapper row full-width justify-center">
        <div class="expandedModule row full-width" v-if="expandModule" :style="{ backgroundImage: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.99) 20%, rgba(0,0,0,.90) 25%, rgba(0,0,0,0) 100%), url(' + selectedModule.banner_path +')'}">
          <q-btn @click="closeModulePreview(); removeHoverEffect();" dense flat icon="close" class="q-ma-sm" style="position: absolute; right: 0; z-index: 2;">
            <q-tooltip>Close</q-tooltip>
          </q-btn>
          <div class="moduleInfo q-pl-xl q-pt-lg">
            <div class="text-h4 text-weight-bold q-mb-xs">{{ selectedModule.title }}</div>
            <div class="text-body1">{{ selectedModule.description }}</div>
            <div class="row q-gutter-md q-mt-lg">
              <q-btn @click="handleModuleClick('previewPlayClick', selectedModule)" style="background: red; color: white" icon="play_arrow" label="Play" />

              <q-btn v-if="isBookmarked(selectedModule.uid)" @click="toggleBookmark(selectedModule)" :loading="bookMarking" outline style="background: white; color: white;" icon="check" label="Bookmarked" />
              <q-btn v-else @click="toggleBookmark(selectedModule)" :loading="bookMarking" outline style="background: white; color: white;" icon="add" label="Bookmark" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { setTimeout } from 'timers'
import { scroll } from 'quasar'

const { getScrollTarget, setScrollPosition } = scroll

export default {
  name: 'ModuleSlider',
  props: [
    'selectedCategory',
    'modules'
  ],
  data () {
    return {
      bookMarking: false,
      hoveredModule: {},
      expandModule: false,
      selectedModule: {},
      moduleSlide: 0,
      currentCategory: null
    }
  },
  computed: {
    ...mapGetters({
      bookmark_uids: 'bookmarks/data'
    }),
    allowedModules () {
      return this.modules.filter(module => this.isAllowedToView(module))
    },
    bookmarkIdArray () {
      return this.bookmark_uids.map(obj => obj.uid)
    },
    chunkedModules () {
      if (window.innerWidth < 1550) {
        return _.chunk(_.toArray(this.modules), 3)
      } else {
        return _.chunk(_.toArray(this.modules), 4)
      }
    },
    isIE () {
      return /msie\s|trident\/|edge\/\d./i.test(window.navigator.userAgent)
    }
  },
  methods: {
    isAllowedToView (module) {
      if (module.browsable === 'true') {
        return true
      } else if (module.browsable === 'false') {
        return false
      } else if (module.browsable === 'restricted') {
        return (this.$store.getters['auth/userDepartmentUids'].some(departmentUid => module.restrictions.departments.includes(departmentUid)) ||
            this.$store.getters['auth/user'].roles.map(role => role.uid).some(roleUid => module.restrictions.roles.includes(roleUid)) ||
            module.restrictions.locations.includes(this.$store.getters['auth/customer']))
      }
    },
    isBookmarked (moduleId) {
      return this.bookmarkIdArray.includes(moduleId)
    },
    handleHover (index, module) {
      // only run this when the expanded preview is open
      if (!this.expandModule) {
        this.addHoverEffect(index, module)
      }

      // check the index after three tenths of a second and if it's the same run the function
      let moduleId = this.hoveredModule.uid
      setTimeout(() => {
        if (moduleId === this.hoveredModule.uid) {
          this.removeHoverEffect()
          this.addHoverEffect(index, this.hoveredModule)
        }
      }, 300)
    },
    addHoverEffect (index, module) {
      let moduleSliderCards = document.querySelectorAll('.moduleSliderCard')

      // if the expanded Preview is open, we only want to add a focused border around the card and not scale it.
      if (this.expandModule) {
        moduleSliderCards.forEach(card => {
          if (card.getAttribute('name').toString() === index.toString()) {
            setTimeout(() => {
              card.classList.add('focused')
              this.selectedModule = module
            }, 150)
          } else {
            setTimeout(() => {
              card.classList.remove('focused')
            }, 150)
          }
        })
      } else {
        moduleSliderCards.forEach(card => {
          if (card.getAttribute('name') < index) {
            card.classList.add('negative-translate')
          } else if (card.getAttribute('name') > index) {
            card.classList.add('positive-translate')
          } else {
            card.classList.add('hovered')
          }
        })
      }
    },
    removeHoverEffect () {
      document.querySelectorAll('.moduleSliderCard').forEach(card => {
        card.classList.remove('negative-translate', 'positive-translate', 'hovered')
      })
    },
    expandModulePreview (index, module) {
      this.removeHoverEffect()
      // stays in sync with CSS transition
      setTimeout(() => {
        this.expandModule = true
        let el = document.getElementById('expandedModulePreview')
        setScrollPosition(getScrollTarget(el), el.offsetTop, 1000)
        this.addHoverEffect(index, module)
        this.selectedModule = module
      }, 150)
    },
    closeModulePreview () {
      this.expandModule = false
      let focusedCard = document.querySelector('.focused')
      if (focusedCard) {
        focusedCard.classList.remove('focused')
      }
    },
    handleModuleClick (which, module, index) {
      // if the preview is shown, we want to disable clicks on the card and only allow clicks from the preview play button
      if (this.expandModule && which !== 'previewPlayClick') return
      if (which === 'expand') {
        this.expandModulePreview(index, module)
      } else {
        this.$router.push({ name: 'module', params: { moduleId: module.uid } }).catch(() => {})
      }
    },
    toggleBookmark (module) {
      this.bookMarking = true
      this.$store.dispatch('bookmarks/bookmarkModule', { moduleId: module.uid }).then(res => {
        this.bookMarking = false
      })
    }
  },
  filters: {
    truncate (string, amount) {
      return string.substring(0, amount)
    }
  },
  created () {
    this.currentCategory = this.$route.params.categoryUid
  },
  updated () {
    if (this.currentCategory !== this.$route.params.categoryUid) {
      this.currentCategory = this.$route.params.categoryUid
      this.closeModulePreview()
    }
  }
}
</script>

<style lang="stylus" scoped>
$scale = 1.4
.q-carousel
  height 200px
.moduleSliderCard
  transition .3s cubic-bezier(.5, 0, .1, 1) transform
  transition-delay .15s
  z-index 1
  &.hovered
    transform scale($scale)
  &.negative-translate
    transform translate(-($scale * 100 - 95px))
  &.positive-translate
    transform translate($scale * 100 - 95px)
.expandedModule
  background-repeat no-repeat
  background-position right
  background-size cover
  height 350px
  max-width 900px
  position relative
  margin-top -1.9rem
.moduleInfo
  color white
  max-width 400px
.expandArrow
  position absolute
  bottom 0
  left 0
  height 4rem
  width 100%
  color white
  background linear-gradient(0deg, rgba(36,34,34,1) 0%, rgba(36,34,34,0.82) 40%, rgba(0,0,0,0) 100%);
  transition .3s
  transition-delay .15s
  opacity 0
  z-index 1

  i
    position absolute
    bottom 4px
.modulePlay
  position absolute
  top 50%
  transform translateY(-50%);
  left 0
  width 100%
  color white
  transition .3s
  transition-delay .15s
  opacity 0
  z-index 2
.moduleSliderCard.hovered
  .modulePlay
  .expandArrow
    opacity 1

    i:hover
      color red

.focused
    border: solid 4px red;
    -webkit-box-shadow: 0 4px 4px rgba(0,0,0,.2);
    -moz-box-shadow: 0 4px 4px rgba(0,0,0,.2);
    box-shadow: 0 4px 4px rgba(0,0,0,.2);
    &::before
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -13px;
      border-style: solid;
      border-width: 7px 13px 0 13px;
      border-color: rgba(0,0,0,.15) transparent transparent transparent;
      margin-top: 4px;
    &::after
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -13px;
      border-style: solid;
      border-width: 7px 13px 0 13px;
      margin-top: 3px;
      border-color: red transparent transparent transparent;
</style>

<style lang="stylus">
.moduleInfo .q-btn i.q-icon
  font-size 12px
</style>
